<script>
import Layout from '@/views/layouts/main'
import { onMounted, ref, watch } from 'vue'
import { getSellers, promoteAccount, demoteAccount } from './api'
import AppPagination from '@/components/app-pagination'
import Notify from '@/notify'
import debounce from '@/utils/debounce'
import { formatPhoneNumber } from '@/utils/phone'
import Swal from 'sweetalert2'

export default {
  setup () {
    const sellersList = ref({})
    const page = ref(1)
    const pages = ref(0)
    const total = ref(0)
    const filter = ref({
      orX: null,
      seller_approved: null,
      created_at: {
        from: null,
        to: null
      }
    })

    const dateToISO = (date) => {
      if (date && date.length === 10) {
        return date.split('/').reverse().join('-')
      }
      return null
    }

    const togglePlan = (seller) => {
      const title = `Tornar este vendedor ${seller.free_seller ? 'PREMIUM' : 'FREE'}`
      const confirmButtonText = seller.free_seller ? 'Confirmar UPGRADE' : 'Confirmar DOWNGRADE'

      const extra = seller.free_seller
        ? {
          input: 'checkbox',
          inputValue: true,
          inputPlaceholder: 'Liberar sorteios pendentes de pagamento'
        } : {}

      Swal.fire({
        title,
        confirmButtonText,
        ...extra
      }).then(({ isConfirmed, value }) => {
        if (!isConfirmed) {
          return
        }

        const enableContests = value === 1
        if (seller.free_seller) {
          promoteAccount(seller.id, { enable_contests: enableContests }).then(() => {
            seller.free_seller = false
            Swal.fire(
              'Upgrade aplicado!',
              'Esta conta agora é PREMIUM',
              'success'
            )
          })
        } else {
          demoteAccount(seller.id).then(() => {
            seller.free_seller = true
            Swal.fire(
              'Downgrade aplicado!',
              'Esta conta agora é FREE',
              'success'
            )
          })
        }
      })
    }

    onMounted(() => {
      fetchSellers()
    })

    const fetchSellers = debounce(() => {
      const params = {
        page: page.value,
        orX: filter.value.orX,
        seller_approved: filter.value.seller_approved,
        created_at: {
          gte: dateToISO(filter.value.created_at.from),
          lte: dateToISO(filter.value.created_at.to)
        },
        order: {
          name: 'asc'
        }
      }

      getSellers(params).then((data) => {
        sellersList.value = data.data
        pages.value = data.last_page
        total.value = data.total
      }).catch(() => {
        Notify.create({
          title: 'Falha ao carregar os vendedores',
          timer: 1500
        })
      })
    }, 1000)

    watch(filter, () => {
      page.value = 1
      fetchSellers()
    }, { deep: true })

    return {
      page,
      pages,
      total,
      sellersList,
      fetchSellers,
      formatPhoneNumber,
      filter,
      togglePlan
    }
  },
  components: {
    AppPagination,
    Layout
  }
}

</script>

<template>
  <Layout :pagetitle="'Vendedores'">
    <div class="row">
      <div class="col-lg-12">
        <div class="col-md-12">
          <div
            class="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3"
          >
            <div>
              <ul class="nav nav-pills">
                <div>
                  <router-link
                    :to="`/sellers/add`"
                    class="btn btn-primary"
                  >
                    <i class="bx bx-plus me-1" /> Novo vendedor
                  </router-link>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-lg-3 mb-2">
                <input
                  v-model="filter.orX"
                  type="text"
                  class="form-control"
                  placeholder="Pesquisar...."
                >
              </div>
              <div class="col-sm-12 col-lg-3 mb-2">
                <input
                  v-model="filter.created_at.from"
                  v-maska="'##/##/####'"
                  type="text"
                  class="form-control"
                  placeholder="Data de cadastro(inicial)"
                >
              </div>
              <div class="col-sm-12 col-lg-3 mb-2">
                <input
                  v-model="filter.created_at.to"
                  v-maska="'##/##/####'"
                  type="text"
                  class="form-control"
                  placeholder="Data de cadastro(final)"
                >
              </div>
              <div class="col-sm-12 col-lg-3 mb-2">
                <select
                  v-model="filter.seller_approved"
                  class="form-select"
                >
                  <option :value="null">
                    Todos
                  </option>
                  <option :value="true">
                    Aprovados
                  </option>
                  <option :value="false">
                    Não aprovados
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-striped mb-4"
              >
                <thead>
                  <tr>
                    <th scope="col">
                      Nome
                    </th>
                    <th scope="col">
                      WhatsApp
                    </th>
                    <th scope="col">
                      E-mail
                    </th>
                    <th scope="col">
                      Domínios
                    </th>
                    <th scope="col">
                      Username
                    </th>
                    <th scope="col">
                      Bloqueado
                    </th>
                    <th class="col">
                      Premium
                    </th>
                    <th scope="col">
                      Cadastrado em
                    </th>
                    <th scope="col">
                      Sorteios
                    </th>
                    <th scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="seller in sellersList"
                    :key="seller.id"
                  >
                    <td>{{ seller.name }}</td>
                    <td>{{ formatPhoneNumber(seller.whatsapp) }}</td>
                    <td>{{ seller.email }}</td>
                    <td>
                      <b-dropdown
                        v-if="Array.isArray(seller.domains)"
                        :text="`Total: ${seller.domains.length}`"
                        size="sm"
                        variant="outline-dark"
                        style="width: 100%;"
                      >
                        <b-dropdown-item
                          v-for="(domain, i) in seller.domains"
                          :key="i"
                          :href="domain"
                          target="_blank"
                        >
                          {{ domain }}
                        </b-dropdown-item>
                      </b-dropdown>
                      <b-button
                        v-else
                        variant="outline-dark"
                        size="sm"
                        style="width: 100%;"
                        disabled
                      >
                        Não possui
                      </b-button>
                    </td>
                    <td>{{ seller.username }}</td>
                    <td>
                      <span
                        v-if="seller.seller_approved === true"
                        class="badge rounded-pill badge-soft-danger mb-0"
                        style="color: green"
                      >Ativo
                      </span>
                      <span
                        v-else
                        class="badge rounded-pill badge-soft-success mb-0"
                        style="color: red"
                      >Bloqueado
                      </span>
                    </td>
                    <td>
                      <button
                        class="btn"
                        :class="seller.free_seller ? 'btn-outline-danger' : 'btn-outline-success'"
                        type="button"
                        @click="togglePlan(seller)"
                      >
                        <i :class="seller.free_seller ? 'bx bx-block' : 'bx bx-user-check'" />
                        {{ seller.free_seller ? 'Não' : 'Sim' }}
                      </button>
                    </td>
                    <td>{{ seller.created_at ? $d(seller.created_at, 'info') : '' }}</td>
                    <td>{{ seller.contest ? seller.contest : 'N/I' }}</td>
                    <td style="width: 10%;">
                      <router-link
                        :to="`/sellers/${seller.id}/edit`"
                        class="btn btn-outline-info"
                        style="width: 100%"
                      >
                        <i class="bx bx-cog" />
                        Gerenciar
                      </router-link>
                      <!-- <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <router-link :to="`/sellers/${seller.id}/edit`">
                            <a
                              data-bs-placement="top"
                              title="Editar"
                              class="px-2 text-primary"
                            ><i class="bx bx-pencil font-size-18" /></a>
                          </router-link>
                        </li>
                      </ul> -->
                    </td>
                  </tr>
                </tbody>
              </table>
              <app-pagination
                v-model="page"
                class="pagination"
                :pages="pages"
                :range-size="1"
                @update:modelValue="fetchSellers"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

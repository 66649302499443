export const formatPhoneNumber = (phone) => {
  // eslint-disable-next-line
  return phone.replace(/^(\+\d{1,3})?\s?\(?(\d{2})\)?\s?(\d{5})\-?(\d{4})$/, "$1 ($2) $3-$4")
}

export const clearFormat = (maskedPhone) => {
  const prefix = maskedPhone.startsWith("+") ? '+' : ''
  return prefix + (maskedPhone
    .replace(/\D/g, ''))
}
